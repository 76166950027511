import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Portfolio.module.css'; // Import your CSS Module
import PortfolioImage from '../assets/img/portfolio.jpeg';
import Button from 'react-bootstrap/Button'; // Import the Button component
import ImageLayout from '../components/Imagelayout';


function Portfolio() {
    useEffect(() => {
        document.title = 'Codedelic - Portfolio';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);
    return (
        <div className='container container-fluid'>
            <div className="row">
                <div className={styles.imgcontainer}>
                    <img src={PortfolioImage} alt="Landing" className='img-fluid' />
                    <div className={styles.overlayheading}>
                        Portfolio
                    </div>
                    <div className={styles.overlaysub}>
                        View our work
                    </div>
                </div>
                <div className='container' id={styles.ImageLayout}>
                    <h2>Our Projects:</h2>
                    <ImageLayout />
                </div>


            </div>
        </div>
    );
}

export default Portfolio