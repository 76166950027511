import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './contact.module.css';
import ContactImage from '../assets/img/contact.jpeg';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';

function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        document.title = 'Codedelic - Contact';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Perform JavaScript validation
        const validationErrors = {};
        if (!formData.firstName) {
            validationErrors.firstName = 'First Name is required';
        }
        if (!formData.lastName) {
            validationErrors.lastName = 'Last Name is required';
        }
        if (!formData.email) {
            validationErrors.email = 'Email is required';
        }
        if (!formData.phone) {
            validationErrors.phone = 'Phone Number is required';
        }
        if (!formData.message) {
            validationErrors.message = 'Message is required';
        }

        // If there are validation errors, set them in state and prevent submission
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // Send email using emailjs
        emailjs.send('service_jyznu2p', 'template_xsk751k', {
            from_name: `${formData.firstName} ${formData.lastName}`,
            from_email: formData.email,
            phone: formData.phone,
            message: formData.message,
        }, '8DTAt8l_GRzUJPRoT')
            .then((response) => {
                setSuccessMessage('Email sent successfully!');
            })
            .catch((error) => {
                console.error('Email send error:', error);
                setErrorMessage('Failed to send email. Please try again later.');
            });
    };
    return (
        <div className='container container-fluid'>
            <div className="row">
                <div className={styles.imgcontainer}>
                    <img src={ContactImage} alt="Landing" className='img-fluid' />
                    <div className={styles.overlayheading}>
                        Contact Us
                    </div>
                    <div className={styles.overlaysub}>
                        Connect With Us Today
                    </div>
                </div>

                <div className="container mt-5" id={styles.form}>
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group" id={styles.formGroup}>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    id={styles.inp}
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group" id={styles.formGroup}>
                                <input
                                    type="text"
                                    id={styles.inp}
                                    className="form-control"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group" id={styles.formGroup}>
                            <input
                                type="email"
                                id={styles.inp}
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group" id={styles.formGroup}>
                            <input
                                type="tel"
                                id={styles.inp}
                                className="form-control"
                                name="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group" id={styles.formGroup}>
                            <textarea
                                className="form-control"
                                name="message"
                                id={styles.inp}
                                rows="4"
                                placeholder="Your Message"
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                            ></textarea>
                        </div>
                        <Button type="submit" variant="primary">Submit</Button>
                    </form>
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                </div>
            </div>
        </div>
    );
}

export default Contact