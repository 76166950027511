import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Home.module.css'; // Import your CSS Module
import LandingImage from '../assets/img/landing.png';
import Button from 'react-bootstrap/Button'; // Import the Button component
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import ThreeCards from '../components/3cards';
import TwoCards from '../components/2cards';
import PlacesSearch from '../components/PlacesSearch';


function Home() {
    useEffect(() => {
        document.title = 'Codedelic - Home';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);

    return (
        <div className='container container-fluid'>
            <div className="row">
                <div className={styles.imgcontainer}>
                    <img src={LandingImage} alt="Landing" className='img-fluid' />
                    <div className={styles.overlayheading}>
                        Experience Pristine Perfection
                    </div>
                    <div className={styles.overlaysub}>
                        Handcrafting Elegant Websites for You
                    </div>
                    <div className={styles.overlaybutton}>
                        <Button variant="primary" className={styles.button} type='button' as={Link} to="/contact">Contact Us</Button>
                        <PlacesSearch></PlacesSearch>
                    </div>
                </div>
                <div className='container'>
                    <div className={styles.abtsectionback}> </div>
                    <div className={styles.abtsectionfront}>
                        <h2> About Us</h2>
                        <img src={Logo} alt="Logo" className='img-fluid' id={styles.logo} />
                        <p className={styles.paraabt}>
                            At CodeDelic, we are more than just a web development agency; we are your digital partners in success. Founded on a passion for innovation and a commitment to excellence, our journey began with a simple idea: to craft online experiences that inspire and empower.<br />
                            <Button variant="primary" className={styles.btn} type='button' as={Link} to="/about">Read More</Button>
                        </p>
                    </div>
                </div>
                <div className='container'>
                    <div className={styles.masterservice}>
                        <h2>Services</h2>
                        <div className='cards'>
                            <ThreeCards />
                        </div>
                        <Button variant="primary" className={styles.btnn} type='button' as={Link} to="/services">View More</Button>
                    </div>
                </div>

                <div className='container'>

                    <div id={styles.interest}>

                        <h2 id={styles.interest1}>Interested?</h2>
                        <h5 id={styles.interest1}>View Some of our work <a href='/Portfolio'>Here</a></h5>
                        <h6 id={styles.interest1}>Hear From our clients:</h6>
                        <TwoCards />

                        <Button variant="primary" className={styles.button2} type='button' as={Link} to="/contact">Contact Us</Button>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default Home;
