import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './8cards.module.css'


function EightCards() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="card mb-3" id={styles.custom1}>
                        <div className="card-body">
                            <h5 className="card-title">Website Development</h5>
                            <ul>
                                <li>Create custom websites tailored to clients' unique needs.</li>
                                <li>Develop responsive and user-friendly designs.</li>
                                <li>Ensure websites are optimized for speed and performance.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card mb-3" id={styles.custom1}>
                        <div className="card-body">
                            <h5 className="card-title">Custom Websites</h5>
                            <ul>
                                <li>Craft fully customized websites from the ground up.</li>
                                <li>Tailor designs and functionalities to match clients' branding and objectives.</li>
                                <li>Provide unique and memorable online experiences.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card mb-3" id={styles.custom1}>
                        <div className="card-body">
                            <h5 className="card-title">SEO (Search Engine Optimization)</h5>
                            <ul>
                                <li>Optimize websites for higher search engine rankings.</li>
                                <li>Perform keyword research and on-page optimization.</li>
                                <li>Improve online visibility and organic traffic.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card mb-3" id={styles.custom1}>
                        <div className="card-body">
                            <h5 className="card-title">Maintenance</h5>
                            <ul>
                                <li>Provide regular website updates and maintenance.</li>
                                <li>Ensure website security, fix bugs, and optimize performance.</li>
                                <li>Keep content fresh and up to date.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card mb-3" id={styles.custom2}>
                        <div className="card-body">
                            <h5 className="card-title">Online Presence Management</h5>
                            <ul>
                                <li>Comprehensive management of clients' online presence.</li>
                                <li>Services include social media management, SEO, and content marketing.</li>
                                <li>Enhance brand visibility and engagement across digital platforms.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card mb-3" id={styles.custom2}>
                        <div className="card-body" >
                            <h5 className="card-title">Social Media Management</h5>
                            <ul>
                                <li>Create and curate engaging social media content.</li>
                                <li>Develop social media strategies and content calendars.</li>
                                <li>Monitor and respond to audience engagement and inquiries.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card mb-3" id={styles.custom2}>
                        <div className="card-body">
                            <h5 className="card-title">Content Marketing</h5>
                            <ul>
                                <li>Develop content strategies aligned with client goals.</li>
                                <li>Produce high-quality blog posts, articles, and multimedia content.</li>
                                <li>Enhance online authority and engage target audiences.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="card mb-3" id={styles.custom2}>
                        <div className="card-body">
                            <h5 className="card-title">Local SEO</h5>
                            <ul>
                                <li>Optimize online presence for local search.</li>
                                <li>Manage business listings, reviews, and maps.</li>
                                <li>Boost visibility in local search results.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EightCards;
