import React from 'react';
import { Link } from 'react-router-dom';
import './module.footer.css';

function Footer() {
    return (
        <footer>
            <div className="container py-5">
                <div className="row">
                    {/* Column 1 */}
                    <div className="col-md-3 mb-3" id='one'>
                        <h5>Company</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/Portfolio">Portfolio</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/contact">Contact us</Link></li>
                            <li><Link to="/services">Services</Link></li>
                        </ul>
                    </div>

                    {/* Column 2 */}
                    <div className="col-md-6 d-flex flex-column align-items-center mb-3" id='two'>
                        <h5 className="mb-3">Services</h5>
                        {/* Subsection 1 */}
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <h6>Website Development</h6>
                                <ul className="list-unstyled">
                                    <li>Custom Websites</li>
                                    <li>SEO</li>
                                    <li>Maintenance</li>
                                </ul>
                            </div>
                            {/* Subsection 2 */}
                            <div className="col-md-6 mb-3">
                                <h6>Online Presence Management</h6>
                                <ul className="list-unstyled">
                                    <li>Social Media Management</li>
                                    <li>Content Marketing</li>
                                    <li>Local SEO</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Column 3 */}
                    <div className="col-md-3 mb-3" id='three'>
                        <h5>Contact</h5>
                        <ul className="list-unstyled">
                            <li><a href="https://www.linkedin.com/company/codedelic">Linkedin</a></li>
                            <li><a href="mailto:info.codedelic@gmail.com">Email</a></li>
                            <li><a href="tel:+17785943295">Phone</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
