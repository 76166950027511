import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './3cards.module.css';

function ThreeCards() {
    return (
        <div className="container">
            <div className="row">
                {/* Card 1 */}
                <div className="col-md-4">
                    <div className="card" id={styles.custom}>
                        <div className="card-body">
                            <h5 className="card-title"><b>Custom Website Development</b></h5>
                            <h6 className="card-subtitle mb-2">Crafting websites that reflect your brand</h6>
                        </div>
                    </div>
                </div>

                {/* Card 2 */}
                <div className="col-md-4">
                    <div className="card" id={styles.custom}>
                        <div className="card-body">
                            <h5 className="card-title">Online Presence Management</h5>
                            <h6 className="card-subtitle mb-2">Managing full online brand presence</h6>
                        </div>
                    </div>
                </div>

                {/* Card 3 */}
                <div className="col-md-4">
                    <div className="card" id={styles.custom}>
                        <div className="card-body">
                            <h5 className="card-title">Strategic Digital Consulting</h5>
                            <h6 className="card-subtitle mb-2">Guiding digital strategy for success</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThreeCards;
