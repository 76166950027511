// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './module.navbar.css';

function Navbar() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    return (
        <BootstrapNavbar bg="transparent" expand="lg" variant="light">
            <div className="container">
                {/* Small Navbar Brand - Visible on Mobile */}
                <h4 className={`navbar-brand NavbarBrandsmall`}>
                    <b>Codedelic</b>
                </h4>
                <BootstrapNavbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    className="ml-auto"
                    onClick={handleNavCollapse}
                />
                <BootstrapNavbar.Collapse>
                    <div className="d-lg-flex justify-content-between align-items-center w-100">
                        <ul className={`navbar-nav navflex`}>
                            <li className={`nav-item item`}>
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                            </li>
                            <li className={`nav-item item`}>
                                <Nav.Link as={Link} to="/about">About us</Nav.Link>
                            </li>
                            <li className={`nav-item item`}>
                                <Nav.Link as={Link} to="/services">Services</Nav.Link>
                            </li>
                            {/* Big Navbar Brand - Visible on Laptops and Big Screens */}
                            <li className={`nav-item item`}>
                                <h4 className={`navbar-brand newNavbarBrandbig`}>
                                    <b>Codedelic</b>
                                </h4>
                            </li>
                            <li className={`nav-item item`}>
                                <Nav.Link as={Link} to="/Portfolio">Portfolio</Nav.Link>
                            </li>
                            <li className={`nav-item item`}>
                                <Nav.Link as={Link} to="/blog">Blog</Nav.Link>
                            </li>
                            <li className={`nav-item item`}>
                                <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
                            </li>
                        </ul>
                    </div>
                </BootstrapNavbar.Collapse>
            </div>
        </BootstrapNavbar>
    );
}

export default Navbar;
