import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './About.module.css'; // Import your CSS Module
import AboutImage from '../assets/img/about.jpg';
import Button from 'react-bootstrap/Button'; // Import the Button component
import me from '../assets/img/me.png';
function About() {
    useEffect(() => {
        document.title = 'Codedelic - About';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);
    return (
        <div className='container container-fluid'>
            <div className="row">
                <div className={styles.imgcontainer}>
                    <img src={AboutImage} alt="Landing" className='img-fluid' />
                    <div className={styles.overlayheading}>
                        About Us
                    </div>
                    <div className={styles.overlaysub}>
                        Get To Know Us
                    </div>
                </div>

                <div className='container'>
                    <div className={styles.abtsectionback}> </div>
                    <div className={styles.abtsectionfront}>
                        <p className={styles.paraabt}>
                            At <b>Codedelic</b>, we are more than just a web development agency; we are your digital partners in success. Founded by <b>Harman Sahota</b> on a passion for innovation and a commitment to excellence, our journey began with a simple idea: to craft online experiences that inspire and empower.
                        </p>
                        <h2 className={styles.cursive}>Our Founder</h2>
                        <p className={styles.paraabt}>
                            <b>Harman Sahota</b>, the visionary leader behind Codedelic, holds a <b>Bachelor of Science in Computer Science</b> from <b>the University of British Columbia</b>. With a track record of creating beautiful and responsive interfaces that help companies establish a remarkable online presence, Codedelic is committed to delivering cutting-edge solutions to our clients. Our leadership and expertise ensure that Codedelic stands at the forefront of web development.
                        </p>
                        <h2 className={styles.cursive}>Our Mission</h2>
                        <p className={styles.paraabt}>
                            In the wake of the COVID-19 pandemic, establishing a strong online presence has become more crucial than ever for businesses. At Codeelic, we are on a mission to help companies thrive in this digital age by crafting stellar online identities that not only increase their customer base but also elevate their brand's reputation. With a focus on personalized service and a deep passion for web development, we are here to guide you through every step of this transformative journey, one pixel at a time
                        </p>
                    </div>
                </div>
                <div className={styles.contain}>
                    <div className={styles.profile}>
                        <img src={me} alt="myself" className='img-fluid' />
                    </div>
                    <h4 className={styles.title}><a href='https://www.linkedin.com/in/harman-s-sahota/' target="_blank">Harman Sahota</a><br />Founder</h4>
                </div>
            </div>
        </div>
    );
}

export default About