import React from 'react';
import styles from './ImageLayout.module.css'; // Import styles from the module.css file
import Masonry from 'react-masonry-css';
import EldercareImage from '../assets/img/eldercare.png';
import nachdijawani from '../assets/img/nachdijawani.png';
import ifkt from '../assets/img/ifkt.png';

const images = [
    {
        url: 'https://marvelous-people-67b.notion.site/Gentle-Healing-Aged-Care-e50b053ff446400185b6f7f306075677',
        source: EldercareImage,
        hoverText: 'Gentle Healing Aged Care', // Add hover text for the first image
    },
    {
        url: 'https://grey-client-296.notion.site/Nachdi-Jawani-5326b0ed58c442f0a903b02af7e4241e',
        source: nachdijawani,
        hoverText: 'Nachdi Jawani'
    },
    {
        url: 'https://ifktnam.ca',
        source: ifkt,
        hoverText: 'IFKT'
    },
    // Add more objects with URLs, image sources, and hover text as needed
];

function ImageLayout() {
    const breakpointColumnsObj = {
        default: 3,
        1280: 2,
        1024: 1,
        768: 1,
    };

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.imageContainer}
            columnClassName={styles.imageColumn}
        >
            {images.map((image, index) => (
                <a href={image.url} key={index} target='_blank' className={styles.imageLink}>
                    <div className={styles.imageItem}>
                        <img src={image.source} alt={`Image ${index + 1}`} className={styles.image} />
                        <div className={styles.overlay}>
                            <p className={styles.overlayText}>{image.hoverText}</p> {/* Use hover text from the data */}
                        </div>
                    </div>
                </a>
            ))}
        </Masonry>
    );
}

export default ImageLayout;
