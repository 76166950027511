import React, { useEffect, useState } from 'react';
import styles from '../pages/Home.module.css'; // Import your CSS Module

function Places() {
    const [places, setPlaces] = useState([]);
    const [currentDay, setCurrentDay] = useState('');
    const [address, setAddress] = useState('');

    useEffect(() => {
        document.title = 'Places';

        const today = new Date().getDay();
        setCurrentDay(today);

        // Parse the address from the URL parameter
        const urlParams = new URLSearchParams(window.location.search);
        const parsedAddress = urlParams.get('address');
        setAddress(parsedAddress);

        // Call a function to fetch nearby places based on the address
        fetchNearbyPlaces(parsedAddress);

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);

    // Function to fetch nearby places using the Google Places API
    const fetchNearbyPlaces = async (address) => {
        try {
            // Convert the address to latitude and longitude using Geocoding API
            const geocodingResponse = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyChjxtgmwyjrmnjq0eRJPo_pLxcOPGMdDc`);
            const geocodingData = await geocodingResponse.json();

            if (geocodingData.results.length === 0) {
                console.error("No results found for the address");
                return;
            }

            const location = geocodingData.results[0].geometry.location;
            const map = new window.google.maps.Map(document.createElement('div'));
            const service = new window.google.maps.places.PlacesService(map);

            const types = ['cafe', 'library', 'coworking'];
            const requests = types.map(type => ({
                location: location,
                radius: '1600',
                keyword: type
            }));

            const results = await Promise.all(requests.map(req => new Promise((resolve, reject) => {
                service.nearbySearch(req, (results, status) => {
                    if (status === 'OK') {
                        resolve(results);
                    } else {
                        reject(status);
                    }
                });
            })));

            const mergedResults = results.reduce((acc, cur) => acc.concat(cur), []);
            setPlaces(mergedResults);
        } catch (error) {
            console.error("Error fetching nearby places:", error);
        }
    };

    const filterTypes = (types) => {
        const relevantTypes = ['cafe', 'library'];
        const filteredTypes = types.filter(type => relevantTypes.includes(type));

        if (!filteredTypes.includes('cafe') && !filteredTypes.includes('library')) {
            filteredTypes.push('coworking space');
        }

        return filteredTypes;
    };

    return (
        <div id={styles.DivPlaces}>
            <h1>Nearby Places</h1>
            <table className="table" id={styles.tablePlaces}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Type</th>
                        <th>Rating</th>
                        <th>Opening Hours</th>
                    </tr>
                </thead>
                <tbody>
                    {places.map((place, index) => (
                        <tr key={index}>
                            <td>
                                <a
                                    href={`https://www.google.com/maps/place/?q=place_id:${place.place_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {place.name}
                                </a>
                            </td>
                            <td>{place.vicinity}</td>
                            <td>{filterTypes(place.types).join(', ')}</td>
                            <td>{place.rating}</td>
                            <td>
                                <span style={{ color: place.opening_hours && place.opening_hours.open_now ? 'green' : 'red' }}>
                                    {place.opening_hours && place.opening_hours.open_now ? 'Open Now' : 'Closed'}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Places;
