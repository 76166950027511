import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './2cards.module.css';

function TwoCards() {
    return (
        <div className="container">
            <div className="row">
                {/* Card 1 */}
                <div className="col-md-6">
                    <div className={`card ${styles.custom}`}>
                        <div className="card-body">
                            <p className="card-text">Codedelic, with its dedicated solo professional, created a stunning website that perfectly represents our brand. Impressive attention to detail and creativity. Highly recommended for custom website development.</p>
                            <p className="card-text text-muted">-Sukhdeep</p>
                        </div>
                    </div>
                </div>

                {/* Card 2 */}
                <div className="col-md-6">
                    <div className={`card ${styles.custom}`}>
                        <div className="card-body">
                            <p className="card-text">Codedelic's solo expert transformed our online presence. From website enhancements to social media strategy, their impact is clear - increased traffic, engagement, and SEO. An invaluable part of our digital strategy.</p>
                            <p className="card-text text-muted">-Mandeep</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TwoCards;
