// PlacesSearch.js
import React, { useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../pages/Home.module.css'; // Import your CSS Module

function PlacesSearch() {
  const inputRef = useRef(null);

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.error("Place not found");
        return;
      }
      window.location.href = `/places?address=${encodeURIComponent(place.formatted_address)}`;
    });
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      window.location.href = `/places?address=${encodeURIComponent(inputRef.current.value)}`;
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      className="form-control" // Bootstrap class for text input
      id={styles.places}
      placeholder="Find a place to work" // Placeholder text
      onKeyDown={handleKeyPress}
    />
  );
}

export default PlacesSearch;
