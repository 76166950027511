import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Blog.module.css'; // Import your CSS Module
import BlogImage from '../assets/img/blog.jpeg';
import Button from 'react-bootstrap/Button'; // Import the Button component

function Blog() {
    useEffect(() => {
        document.title = 'Codedelic - Blog';

        // Optionally, you can reset the title when the component unmounts
        return () => {
            document.title = 'Original Title';
        };
    }, []);
    return (
        <div className='container container-fluid'>
            <div className="row">
                <div className={styles.imgcontainer}>
                    <img src={BlogImage} alt="Landing" className='img-fluid' />
                    <div className={styles.overlayheading}>
                        Blog
                    </div>
                    <div className={styles.overlaysub}>
                        Learn More About the Web
                    </div>
                </div>
                <div className='container' id={styles.comingsoon}>
                <div className="container mt-4" id={styles.cardcont}>
                <a href='https://spotlight.designrush.com/interviews/bring-data-to-life-with-monitoring-platforms-pandora-fms-interview'>
                <div className="card" id={styles.card}>
                 {/* Image placeholder, replace 'image-source.jpg' with your actual image source */}
                <img src="https://images.unsplash.com/photo-1589556763333-ad818080f39e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"  className="card-img-top img-fluid" id={styles.cardImage} alt="Pandora FMS Image"/>
        
                <div className="card-body">
                <h5 className="card-title"><b>Pandora FMS: IT Transformation with Sancho Lerena</b></h5>
                <p className="card-text">Exclusive interview with Sancho Lerena, Founder & CEO of Pandora FMS, discussing the revolution in IT services.</p>
        </div>
      </div>
      </a>
    </div>
                </div>
            </div>
        </div>
    );
}

export default Blog